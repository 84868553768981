import React, { Fragment } from 'react';
import AppleInstructions from './AppleInstructions';
import FitbitInstructions from './FitbitInstructions';
import GarminInstructions from './GarminInstructions';

const Devices = {
    Apple: 1,
    Fitbit: 2,
    Garmin: 3,
};
Object.freeze(Devices);

class Instructions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: Devices.Apple,
        }
    }

    render() {
        var chosenTextClass = "display-4 text-end pointer-cursor move-left-hover"
        var textClass = "display-4 text-end pointer-cursor opacity-50 move-left-hover"

        return(
            <Fragment>
                <h1 className={this.state.selected === Devices.Apple ? chosenTextClass : textClass}
                    onClick={() => this.setState({selected: Devices.Apple})}>
                    apple.
                </h1>
                <h1 className={this.state.selected === Devices.Fitbit ? chosenTextClass : textClass}
                    onClick={() => this.setState({selected: Devices.Fitbit})}>
                    fitbit.
                </h1>
                <h1 className={this.state.selected === Devices.Garmin ? chosenTextClass : textClass}
                    onClick={() => this.setState({selected: Devices.Garmin})}>
                    garmin.
                </h1>

                <div className="mt-5">
                    {this.state.selected === Devices.Apple ? <AppleInstructions /> : null}
                    {this.state.selected === Devices.Fitbit ? <FitbitInstructions /> : null}
                    {this.state.selected === Devices.Garmin ? <GarminInstructions /> : null}
                </div>
            </Fragment>            
        );
    }
}

export default Instructions
import React, { Fragment, useState } from 'react';
import ProgressBar from './ProgressBar';
import Message from './Message';
import visualizeIcon from "../static/icons/bar-chart.svg"

// Import Amplify and Storage
import Amplify, { Storage } from 'aws-amplify';
// Import the project config files and configure them with Amplify
import awsconfig from '../aws-exports';
Amplify.configure(awsconfig);

const FileUpload = ({feature}) => {
    const [file, setFile] = useState("");
    const [email, setEmail] = useState("");
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const [canUpload, setCanUpload] = useState(true);
    const [message, setMessage] = useState("");
    const [walletID, setWalletID] = useState("");


    const handleMessage = (msg) => {
        setMessage(msg);

        // Auto hide alert after 2 sec
        closeAlertAfter(2000);
    }

    const closeAlertAfter = (time) => {
        // Close message after time
        setTimeout(() => {
            if (document.getElementsByClassName("alert").length !== 0) {
                setMessage("");
            }            
        }, time);    
    }

    async function handleSubmit(event) {
        event.preventDefault();

        // Alert if no email, not zip file, or no wallet ID
        if (!email) {
            handleMessage("Please enter your email address")
            return;
        }
        if (feature ===  "Generate NFT" && !walletID) {
            handleMessage("Please enter your Wallet ID");
            return;
        }
        if (!file || file.name.split(".").pop() !== "zip") {
            handleMessage("Invalid file");
            return;
        }

        // Temporarily disable the upload button 
        setCanUpload(false);

        // Upload to S3
        try {
            var fname = feature === "Visualize" ? `${email}.zip` : `${walletID},${email}.zip.nft`;
            await Storage.put(fname, file, {
                level: 'private',
                contentType: 'application/x-zip-compressed',
                progressCallback: progress => {
                    setUploadPercentage(Math.round(progress.loaded * 100 / progress.total))
                }
            })
            .then(res => console.log(res))
            .catch(err => console.log(err));

            
            handleMessage("File successfully uploaded");
        }
        catch(err) {
            if (err.response.status === 500) {
                console.log('There was a problem with the server');
            }
            else {
                console.log(err.response.data.msg);
            }

            handleMessage("File failed to upload");
        }

        // Clear progress bar and alert
        setTimeout(() => setUploadPercentage(0), 2000);

        // Re-enable the upload button 
        setCanUpload(true);
    };

    return (
        <Fragment>        
            <div id="file-upload" className="custom-card shadow d-block">
                {/* <a href="#instructions" className="text-decoration-none">
                    <div className="circle-btn ms-auto" id="help-btn">?</div>
                </a>                     */}

                { message ? <Message message={message} /> : null }                    

                <form onSubmit={handleSubmit} autoComplete="off">
                    <div className="form-group d-block">
                        <input type="email" className="form-control text-center input-form" id="inputEmail" aria-describedby="emailHelp" placeholder="Email address"
                        onChange={(e) => setEmail(e.target.value)}/>
                    </div>

                    <div className={feature === "Generate NFT" ? "form-group d-block mt-2" : "d-none"}>
                        <input type="text" className="form-control text-center input-form" id="inputWalletID" aria-describedby="walletHelp" placeholder="Mint Wallet ID"
                        onChange={(e) => setWalletID(e.target.value)}/>
                    </div>

                    <div className="input-group flex-nowrap overflow-hidden my-4 align-items-center justify-content-center">
                        <input type="file" className="d-none" id="inputFile" onChange={(e) => setFile(e.target.files[0])} />
                        <label htmlFor="inputFile" className="ms-0">
                            <div className="btn btn-outline-dark">Choose File</div>
                        </label>                        
                        <div className="d-flex align-items-center ms-4" id="fileName">{ file ? file.name : "No file selected"}</div>
                    </div>

                    <div className="d-flex justify-content-center">
                        <button type="submit" className="btn btn-primary mt-3" disabled={!canUpload}
                        data-toggle="modal" data-target="#exampleModalCenter">
                            <span className="align-middle me-3">{feature}</span>
                            <img className="align-middle" src={visualizeIcon} alt="Visualize" style={{width: 25 + "px"}}></img>
                        </button>
                    </div>                        
                </form>
            </div>

            <div className="position-absolute mt-5" id="progressBar">
                <ProgressBar percentage={uploadPercentage} />
            </div>            
        </Fragment>        
    )
}

export default FileUpload
import React from "react";
import { useState } from "react";
import FileUpload from "./FileUpload";

const styles = {
    tab: [
        "col-md-6",
        "bg-transparent", "border-0",
        "display-4", "text-white"
    ].join(" "),
};
const features = {
    visualize: "Visualize",
    nft: "Generate NFT",
}

const FeatureSelection = () => {
    const [feature, setFeature] = useState(features.visualize);

    const handeClick = (feature) => {
        setFeature(feature);
    }

    return (
        <div className="container">
            <div className="row">
                <p className="text-center display-6">I want to use<br/>my sleep data to</p>
            </div>

            <div className="row mt-md-4">
                <button className={styles.tab + (feature === features.visualize ? " color-orange" : " opacity-50")}
                 onClick={() => handeClick(features.visualize)}>
                    Visualize
                </button>
                <button className={styles.tab + (feature === features.nft ? "" : " opacity-50")}
                 onClick={() => handeClick(features.nft)}>
                    Generate NFT
                </button>
            </div>

            <div className="row mt-4 mt-md-5">
                <div class="col col-lg-6 mx-auto">
                    <FileUpload feature={feature}/>
                </div>          
            </div>
            
        </div>
    );
};

export default FeatureSelection;
import React from 'react';
import PropTypes from 'prop-types';

class ProgressBar extends React.Component {

    render() {
        const { percentage } = this.props;

        return(
            <div className="progress custom-progress" style={{height: 10 + "px"}}>
                <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: percentage + "%" }}
                    aria-valuenow={percentage}
                    aria-valuemin="0" aria-valuemax="100">
                        {percentage}%
                </div>
            </div>
        );
    }
}

ProgressBar.propTypes = {
    percentage: PropTypes.number.isRequired,
}

export default ProgressBar
import './static/css/sleep_track.css';
import Instructions from './components/Instructions';
import { Fragment } from 'react';
import logo from './static/icons/logo.svg';
import FeatureSelection from './components/FeatureSelection';

function App() {
  return (
    <Fragment>
      <header>
        <div className="container pt-5 px-3">
          <div className="" id="logo">
            <img src={logo} width="150px" alt="logo"></img>
          </div>
        </div>
      </header>

      <div className="container position-relative section min-vh-100 py-5 px-3" id="landing">
        <div className="row">
          <FeatureSelection/>
        </div>        
      </div>

      <div className="container section my-5">
        <h1 className="display-2 text-end text-accent-colored" id="instructions">pick your device.</h1>
        <Instructions />
      </div>

      <footer>
        <div className="container pt-5 px-3">
          <div className="" id="logo">
            <img src={logo} width="150px" alt="logo"></img>
          </div>
        </div>     
      </footer>
    </Fragment>    
  );
}

export default App;

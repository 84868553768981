import React from 'react';
import PropTypes from 'prop-types';

class Message extends React.Component {

    render() {
        const { message } = this.props;
        return(
            <div className="alert alert-info alert-dismissible fade show" role="alert" >
                { message }
            </div>
        );
    }
}

Message.propTypes = {
    message: PropTypes.string.isRequired,
}

export default Message
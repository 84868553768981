import React, { Fragment } from 'react';

class GarminInstructions extends React.Component {

    render() {

        return(
            <Fragment>
                <p>Sorry, we are still working to support Garmin data. Please check back in the future!
                </p>
            </Fragment>            
        );
    }
}

export default GarminInstructions
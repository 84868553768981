import React, { Fragment } from 'react';

class FitbitInstructions extends React.Component {

    render() {
        const instructions = [
            "From the fitbit.com dashboard, click the gear icon.",
            "Click Settings > Data Export",
            "Under Export Your Account Archive, click Request Data.",
            "You’ll receive an email at the address associated with your Fitbit account. Open the email and confirm your data archive request.",
            "You’ll receive another email with a link to download your Fitbit account data.",
            "Download and save the data file to your computer. If you have a Fitbit family account, you’ll see separate folders for each child account.",
            "Upload your data as a .zip file to us.",
            "Check your inbox for an email containing your sleep chart.",
            "Share your chart with friends and family!"
        ]

        let body = []

        for (let i = 0; i < instructions.length; i++) {
            body.push(
                <div className="position-relative custom-card shadow">
                    <div className="position-absolute instruction-number">{i + 1}</div>
                    <p>{instructions[i]}</p>
                </div>
            )
        }

        return(
            <Fragment>
                <div className="tabs">
                    {body}
                </div>
                
            </Fragment>            
        );
    }
}

export default FitbitInstructions
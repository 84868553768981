import React, { Fragment } from 'react';

class AppleInstructions extends React.Component {

    render() {
        const instructions = [
            "Open the Health App on your iPhone.",
            "Tap your profile picture or initials at the top right.",
            "Scroll to bottom and tap export all data.",
            "Upload your export.zip file to us.",
            "Check your inbox for an email containing your sleep chart.",
            "Share your chart with friends and family!"
        ]

        let body = []

        for (let i = 0; i < instructions.length; i++) {
            body.push(
                <div className="position-relative custom-card shadow">
                    <div className="position-absolute instruction-number">{i + 1}</div>
                    <p>{instructions[i]}</p>
                </div>
            )
        }

        return(
            <Fragment>
                <p className="my-5 ps-4 border-left">In addition to your sleep data, the Apple Health app may export other health data from your device.
                    <br/><strong className="text-accent-colored" style={{fontWeight: 900}}>We will only access your sleep data!
                    <br/>We also don't store your data!</strong>
                    <br/>We understand your concerns about sharing information, so please stop here if you're not comfortable with the process.
                </p>

                <div className="tabs">
                    {body}
                </div>
                
            </Fragment>            
        );
    }
}

export default AppleInstructions